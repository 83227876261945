.home {
  .nux {
    text-align: center;

    .logo-large {
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

  .repository {
    position: relative;
    min-height: 62px;

    &.path-removed {
      .list-group-item-heading {
        color: #cf5353;
      }
    }

    .arrow-icon {
      color: #686868;
    }

    &:hover .arrow-icon {
      color: #a5a5a5;
    }
  }
}
